import React from 'react';

import { Flex, Image, Text, Link } from '@chakra-ui/react';
import lnGold from '../../images/social/ln-gold.svg';

export type TeamMemberProps = {
  picture: string;
  name: string;
  role: string;
  linkedinURL?: string;
  description?: string;
};

const TeamMember = ({
  picture,
  name,
  role,
  linkedinURL,
  description,
}: TeamMemberProps): JSX.Element => {
  return (
    <Flex direction="column" alignItems="center" w="310px">
      <Image
        src={picture}
        borderRadius="38px 38px 38px 38px"
        height="200px"
        minH="200px"
        minW="200px"
        mb="24px" />
      <Text fontSize="lg" fontWeight="500" mb="4px">
        {name}
      </Text>
      { role && (
        <Text fontSize="lg" mb="16px" color="gray.700">
          {role}
        </Text>
      )}
      {linkedinURL && (
        <Link href={linkedinURL} target="_blank">
          <img src={lnGold} alt="li" />
        </Link>
      )}
      {description && (
        <Text textAlign="center" fontSize="sm" whiteSpace="pre-line">
          {description}
        </Text>
      )}
    </Flex>
  );
};

export default TeamMember;
