import React from 'react';
import { Box, Flex, Heading, SimpleGrid } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PartnerItem from '../../../components/PartnerItem';

import OtherCompanies from './otherCompanies';

const Partners = (): JSX.Element => {
  const { t } = useTranslation('partners');

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      direction="column"
      bg="bg.gradient"
      w="100%"
      px={{ base: '20px', md: '112px' }}
      py={{ base: '20px', md: '96px' }}
      flexWrap={{ base: 'wrap' }}
    >
      <Flex direction="column">
        <Heading size="xs" mb="48px" mt={{ base: '48px' }}>
          {t('partners')}
        </Heading>
        <SimpleGrid
          columns={{ base: 0, sm: 2 }}
          spacingY={{ base: '48px', md: '60px' }}
          spacingX="60px"
        >
          {OtherCompanies.map((company) => (
            <Box key={company.name} display="flex" justifyContent="center">
              <PartnerItem
                name={company.name}
                logo={company.logo}
                logoHeight={company.logoHeight}
                description={t(company.descText)}
                website={company.website}
              />
            </Box>
          ))}
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};

export default Partners;
