// Operators
import operator1 from '../../../images/companies/adecoagro.png';
import operator2 from '../../../images/companies/edp.png';
import operator3 from '../../../images/companies/managro.png';
import operator4 from '../../../images/companies/quemu.png';
import operator5 from '../../../images/companies/capelle.png';
import operator6 from '../../../images/companies/espartina.png';

const OperatorsList = [
  {
    logo: operator1,
    website: 'https://www.adecoagro.com/',
  },
  {
    logo: operator2,
    website: 'https://www.edpagro.com.ar/',
  },
  {
    logo: operator3,
    website: 'https://www.managro.com.ar/',
  },
  {
    logo: operator4,
    website: 'https://www.grupoquemu.com.ar/',
  },
  {
    logo: operator5,
    website: 'https://www.chsa.com.ar/',
  },
  {
    logo: operator6,
    website: 'https://www.espartina.com.ar/',
  },
];

export default OperatorsList;
