/* eslint-disable no-unused-vars */
import React from 'react';

import { Flex, Image, Text, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export type CompanyItemProps = {
  logo: string;
  description?: string;
  website: string;
  align?: string;
  big?: boolean;
  isFounder: boolean;
};

const CompanyItem = ({
  logo,
  description,
  website,
  align,
  big,
  isFounder,
}: CompanyItemProps): JSX.Element => {
  const { t } = useTranslation('team-members');
  return (
    <Flex w={{ base: '390', xs: '413px' }} direction="row" alignItems={align || 'center'}>
      <Link href={website} target="_blank">
        {website.includes('adecoagro') && !isFounder && (
          <Image src={logo} maxW={{ base: '192px' }} mt={50} />
        )}
        {website.includes('edpagro') && !isFounder && (
          <Image src={logo} maxW={{ base: '130px' }} mt={50} />
        )}
        {website.includes('espartina') && !isFounder && (
          <Image src={logo} maxW={{ base: '170px' }} mt={50} />
        )}
        {website.includes('managro') && !isFounder && (
          <Image src={logo} maxW={{ base: '200px' }} mt={50} />
        )}
        {website.includes('quemu') && !isFounder && (
          <Image src={logo} maxW={{ base: '150px' }} mt={50} />
        )}
        {website.includes('chsa') && !isFounder && (
          <Image src={logo} maxW={{ base: '130px' }} mt={50} />
        )}
      </Link>

      {website.includes('agrotoken') && isFounder && (
        <Flex direction="column" w="320px" alignItems={align || 'center'}>
          <Image src={logo} h={big ? '40px' : '27px'} mb="12px" />
          <Text color="gray.700" mb="24px">
            {description}
          </Text>
          <Link href={website} target="_blank">
            <Text textDecoration="underline" color="gray.700">
              {t('visit-web')}
            </Text>
          </Link>
        </Flex>
      )}

      {website.includes('adecoagro') && isFounder && (
        <Flex direction="column" w="320px" alignItems={align || 'center'}>
          <Image src={logo} h={big ? '40px' : '27px'} mb="12px" />
          <Text color="gray.700" mb="24px">
            {description}
          </Text>
          <Link href={website} target="_blank">
            <Text textDecoration="underline" color="gray.700">
              {t('visit-web')}
            </Text>
          </Link>
        </Flex>
      )}
    </Flex>
  );
};

export default CompanyItem;
