// Team Members
import Member1 from '../../../images/team/member1.svg';
import Member2 from '../../../images/team/member2.svg';
import Member3 from '../../../images/team/member-TL.jpg';
import Member4 from '../../../images/team/ux-specialist.jpg';

const Members = [
  {
    picture: Member1,
    name: 'Matías Simone',
    roleText: 'member1.role',
    linkedinURL: 'https://www.linkedin.com/in/simonematias/',
  },
  {
    picture: Member2,
    name: 'Agustín Pearson',
    roleText: 'member2.role',
    linkedinURL: 'https://www.linkedin.com/in/agustinpearson/',
  },
  {
    picture: Member3,
    name: 'Christian Bouvier',
    roleText: 'member3.role',
    linkedinURL: 'https://www.linkedin.com/in/christianbouvier/',
  },
  {
    picture: Member4,
    name: 'Ezequiel Correa Luna',
    roleText: 'member4.role',
    linkedinURL: 'https://www.linkedin.com/in/ezequiel-correa-luna-44766950',
  },
];

export default Members;
